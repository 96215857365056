import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { APPLICATION_TYPE } from '../../main';
import { ApplicationType } from '../shared/models/application-type';
import { LayoutService } from '../shared/services/layout.service';
import { HomeModule } from './features/home/home.module';
import { IamLayoutModule } from './features/layout/iam.layout.module';
import { IamRoutingModule } from './iam-routing.module';
import { IamAuthService, IamInterceptor } from './services/iam-auth.service';
import { IamCommunicationService } from './services/iam-communication.service';

import { PrivilegedGroupModule } from './features/privileged-group/privileged-group.module';

@NgModule({
    imports: [
        ButtonModule,
        MenubarModule,
        ConfirmDialogModule,
        ToastModule,

        IamLayoutModule,
        HomeModule,

        PrivilegedGroupModule,

        IamRoutingModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),

        //IamStore,
        IamAuthService,
        IamCommunicationService,

        { provide: APPLICATION_TYPE, useValue: ApplicationType.Gps },

        { provide: HTTP_INTERCEPTORS, useClass: IamInterceptor, deps: [IamAuthService, LayoutService], multi: true },
    ],
    //exports: [GpsPermissionDirective],
    //bootstrap: []
})
export class IamModule { }
