<div class="flex mt-6 justify-content-center">

  <div class="p-2 text-center" style="padding:5px; background: linear-gradient(180deg, rgba(1, 70, 103, 0.6) 10%, rgba(33, 150, 243, 0) 30%);">
    <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center">
      <!--header="Login in progress"-->
      <h3>{{message}}</h3>
      <div>
        <i class="pi pi-rotate pi-microchip" style="font-size: 3rem"></i>
        <br />
        Please wait
      </div>
    </div>
  </div>

</div>
