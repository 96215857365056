import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';


@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    MenubarModule,
    ConfirmDialogModule,
    ToastModule
]})
export class IamSharedModule { }
