import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';

import { CommonModule } from '@angular/common';
import { AppLayoutModule } from '../../../portal/features/layout/app.layout.module';
import { IamLayoutLiteComponent } from './components/layout-lite.component';
import { IamLayoutComponent } from './components/layout.component';
import { IamMenuComponent } from './components/menu.component';
import { IamSidebarComponent } from './components/sidebar.component';
import { IamProgressLayoutComponent } from './components/progress.layout.component';
import { IamSharedModule } from '../../iam-shared.module';

@NgModule({
    declarations: [
        IamMenuComponent,
        IamSidebarComponent,
        IamLayoutComponent,
        IamLayoutLiteComponent,
        IamProgressLayoutComponent
    ],
    imports: [
        CommonModule,
        AppLayoutModule,
        ButtonModule,
        MenuModule,
        SidebarModule,
        RippleModule,
        RouterModule,
        ProgressBarModule,

        IamSharedModule
    ],
    exports: [IamLayoutComponent]
})
export class IamLayoutModule { }
