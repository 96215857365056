import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";
import { ApplicationType } from "../../shared/models/application-type";
import { TenantKey } from "../../shared/models/tenant";
import { iamEnvironment } from "../environments/environment";

export class IamConfigService {

  private _tenant?: TenantKey;

  constructor(tenant?: TenantKey) {
    this._tenant = tenant;
  }

  public setTenant(tenant?: TenantKey) {
    this._tenant = tenant;
  }

  get applicationType(): ApplicationType { return ApplicationType.Gps; }

  get tenant(): TenantKey | undefined { return this._tenant; }
  get apiUrl(): string { return this._tenant ? iamEnvironment.api[this._tenant!]!.url : ''; }
  get msalAuthority(): string { return this._tenant ? iamEnvironment.api[this._tenant!]!.authority : ''; }

  getMsalScopes(): string[] {
    return this._tenant ? iamEnvironment.api[this._tenant!]!.scopes : [];
  }

  getMsalConfig(): Configuration {
    return this._tenant ? {
      auth: {
        clientId: iamEnvironment.api[this._tenant!]!.clientId,
        authority: iamEnvironment.api[this._tenant!]!.authority,
        redirectUri: window.location.origin
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        allowRedirectInIframe: true,
        loggerOptions: {
          loggerCallback: (logLevel: LogLevel, message: string) => console.log(message),
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: false
        }
      }
    } : {} as Configuration;
  }
}
