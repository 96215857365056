import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'iam-menu',
    templateUrl: './menu.component.html'
})
export class IamMenuComponent implements OnInit {

  items: any[] = [];

    allItems = [
        //{
        //    label: 'Grant Permission Service',
        //    icon: 'pi pi-fw pi-shield',
        //},
        //{
        //    label: 'Key Rotation Service',
        //    icon: 'pi pi-fw pi-key',
        //},
        //{
        //    label: 'Application Developer Role',
        //    icon: 'pi pi-fw pi-user',
        //},
        {
            label: 'Privilege Role Management',
            icon: 'pi pi-fw pi-id-card',
        }
    ];

  constructor(
  ) { }

  ngOnInit() {
    this.items = this.allItems;
  }
}
