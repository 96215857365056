import { Component, OnInit } from '@angular/core';
import { IamCommunicationService } from '../../../services/iam-communication.service';

@Component({
  selector: 'iam-logout',
  templateUrl: './logout.component.html',
})
export class IamLogoutComponent implements OnInit {

  constructor(
      private communication: IamCommunicationService,
    //private route: ActivatedRoute,
    //private portalCommunication: PortalCommunicationService
  ) {

    //const redirectTo = this.route.snapshot.queryParams['redirect'] ?? '/';

  }

  async ngOnInit() {
    //this.gpsAuth.logout()
    this.communication.userLogout$.next();
    }

}
